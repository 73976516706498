export const STATUS_DRAFT = "DRAFT";
export const STATUS_PENDING = "PENDING";
export const STATUS_INVOICED = "INVOICED";
export const STATUS_PAIED = "PAIED";
export const STATUS_DELIVERED = "DELIVERED";
export const STATUS_CANCELED = "CANCELED";
export const STATUS_VALIDATED = "VALIDATED";

export const DPA = "DPA";
export const CHECK = "CHECK";


export const TRANSACTIONS_STATUS = [
  STATUS_DRAFT,
  STATUS_PENDING,
  STATUS_INVOICED,
  STATUS_PAIED,
  STATUS_DELIVERED,
  STATUS_CANCELED,
  STATUS_VALIDATED,
];

export const PAYMENT_METHODS = [
  DPA,
  CHECK,
];